export const SampleDiagnosisCodes = [["Diagnosis Codes"]];
export const SampleModifierCodes = [["Modifier Codes"]];
export const SampleProcedureCodes = [["Procedure Codes"]];

export const claimsTime = [
    {
        key: '7:00 PM', value: '19:00'
    },
    {
        key: '7:30 PM', value: '19:30'
    },
    {
        key: '8:00 PM', value: '20:00'
    },
    {
        key: '8:30 PM', value: '20:30'
    },
    {
        key: '9:00 PM', value: '21:00'
    },
    {
        key: '9:30 PM', value: '21:30'
    },
    {
        key: '10:00 PM', value: '22:00'
    },
    {
        key: '10:30 PM', value: '22:30'
    },
    {
        key: '11:00 PM', value: '23:00'
    },
    {
        key: '11:30 PM', value: '23:30'
    }
]
export const timeZones = [
    {
        key: 'Central Standard Time - (GMT-6)', value: 'America/Chicago'
    },
    {
        key: 'Mountain Standard Time - (GMT-7)', value: 'America/Denver'
    },
    {
        key: 'Mountain Standard Time - (GMT-7)', value: 'America/Phoenix'
    },
    {
        key: 'Pacific Standard Time - (GMT-8)', value: 'America/Los_Angeles'
    },
    {
        key: 'Alaska Standard Time - (GMT-9)', value: 'America/Anchorage'
    },
    {
        key: 'Hawaii-Aleutian Standard Time - (GMT-10)', value: 'America/Honolulu'
    }
]